import styles from './benefits.module.scss';
import chartIcon from '../images/chart.svg';
import columnIcon from '../images/column-icon.svg';
import lockIcon from '../images/lock-icon.svg';
import stopIcon from '../images/stop-icon.svg';
import checksIcon from '../images/checks.svg';
import noIcon from '../images/no.svg';

const benefits = [
  {
    icon: noIcon,
    header: 'No Listing Fees',
    description:
      'All ERC-20 & ERC-1155 tokens are natively supported, lowering the barrier of entry in terms of both time and money',
  },
  {
    icon: columnIcon,
    header: 'Token Lists',
    description:
      'Projects and Influencers can quickly and easily create official, trusted lists of their tokens, and enjoy fee sharing',
  },
  {
    icon: chartIcon,
    header: 'No Impermanent Loss',
    description:
      'Since users don’t stake liquidity, they’re not subject to IL, giving them more control and less risk',
  },
  {
    icon: stopIcon,
    header: 'Market Depth',
    description:
      'View market depth to gauge the buying and selling interest at different price levels',
  },
  {
    icon: checksIcon,
    header: 'Smart Orders',
    description:
      'Go beyond simple spot trading with advanced features like Limit Orders, Stop Orders, Fill or Kill, Dollar Cost Averaging, and more',
  },
  {
    icon: lockIcon,
    header: 'Real $MYTHIC Utility',
    description:
      'No inflationary time-based emissions and strong utility for governance, staking for features, and fee sharing',
  },
];

function Benefits() {
  return (
    <section className={styles.benefitsSection}>
      <h2>Advantages of MythicSwap</h2>
      <div className={styles.benefits}>
        {benefits.map((benefit) => (
          <div key={benefit.header} className={styles.benefit}>
            <img src={benefit.icon} alt="" />
            <h3>{benefit.header}</h3>
            <p>{benefit.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Benefits;
