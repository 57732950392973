import styles from './interface.module.scss';
import interfaceImg from '../images/mythic-interface.png';

function InterfaceSection() {
  return (
    <section className={styles.interfaceSection}>
      <h2>Trade Like An Expert</h2>
      <div className={styles.interfaceRow}>
        <div className={styles.interfaceColImg}>
          <img src={interfaceImg} alt="" />
        </div>
        <div className={styles.interfaceCol}>
          <p>
            With <strong style={{ color: '#fff' }}>MythicSwap</strong>, anyone can trade like
            an&nbsp;expert.
          </p>
          <p>
            Enjoy a simple but feature-rich UI, providing a more familiar and seamless experience
            for traders.
          </p>
          <p>
            Backed by a secure and robust tech stack to deliver real time data and powerful features
            in the form of Smart Orders.
          </p>
        </div>
      </div>
    </section>
  );
}

export default InterfaceSection;
