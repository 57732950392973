import styles from './whyuse.module.scss';

function WhyMythicSwap() {
  return (
    <section className={styles.whyMythicSwap}>
      <h2>Why MythicSwap?</h2>
      <div className={styles.whyRow}>
        <div className={styles.whyChunk}>
          <h3>Blockchain Enthusiasts</h3>
          <p>
            A decentralized and fully transparent peer to peer trading platform, with immediate
            support for tokens without LPs and listing fees. Allows staking to earn rewards and
            participate in the protocol governance.
          </p>
        </div>
        <div className={styles.whyChunk}>
          <h3>Traditional Traders</h3>
          <p>
            Allows limit orders that allow more complicated trading strategies and smart trading
            tools transitioned from TradeFi&nbsp;to&nbsp;DeFi.
          </p>
        </div>
      </div>
      <div className={styles.whyRow}>
        <div className={styles.whyChunk}>
          <h3>Crypto Influencers</h3>
          <p>
            Provides the ability to customize premium lists of “Top Picks” and earn fee sharing.
          </p>
        </div>
        <div className={styles.whyChunk}>
          <h3>Gamers</h3>
          <p>
            Provides the ability to trade low liquidity tokens and allows projects to customize
            their token lists and earn fee sharing.
          </p>
        </div>
      </div>
    </section>
  );
}

export default WhyMythicSwap;
