import styles from './hero.module.scss';
import logo from '../images/mythicswap-logo.png';
import ksLogo from '../images/ks-logo.png';
import graphs from '../images/graphs.svg';

function Hero() {
  return (
    <>
      <section className={styles.hero}>
        <header>
          <img className={styles.logo} src={logo} alt="Mythic Swap" />
        </header>
        <div className={styles.copy}>
          <h1>A mythical trading platform built for professional traders and gamers</h1>
          <p>
            A decentralized token marketplace, utilizing peer to peer limit orders with tokens
            escrowed in smart contracts, powered by smart tools to enhance the DeFi trading
            experience
          </p>
        </div>
        <div className={styles.broughtToYou}>
          <p>Brought to you by</p>
          <img src={ksLogo} alt="Kingdom Studios" />
        </div>
      </section>
      <div className={styles.graphs}>
        <img src={graphs} alt="" />
      </div>
    </>
  );
}

export default Hero;
