import styles from './token.module.scss';

function TokenSection() {
  return (
    <section className={styles.tokenSection}>
      <h2>
        <strong>$MYTHIC</strong> Token
      </h2>
      <h3>Reward users as they trade!</h3>
      <div className={styles.tokenRow}>
        <div className={styles.tokenColumn}>
          <h4>Dynamic Utility</h4>
          <h5>$MYTHIC has no shortage of benefits and functionality!</h5>
          <ul>
            <li>
              Unlocks premium features
              <ul>
                <li>Customizable list access and creation</li>
                <li>Smart trading tools to make frequent trading easier to execute</li>
                <li>Data visualization of token groups in focus and the overall market</li>
              </ul>
            </li>
            <li>Access to protocol fee sharing and ongoing governance</li>
          </ul>
        </div>
        <div className={styles.tokenColumn}>
          <h4>Sustainable and Rewarding</h4>
          <h5>Automatically rewards users as they trade!</h5>
          <ul>
            <li>Fixed supply of tokens</li>
            <li>Tokens distributed through airdrops and trading activity</li>
            <li>Each trade executed has a portion of the trading fee traded into $MYTHIC</li>
            <li>$MYTHIC rewards provided to buyers, sellers, and stakers</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default TokenSection;
