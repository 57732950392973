import styles from './footer.module.scss';
import circle from '../images/circle.svg';
import triangle from '../images/triangle.svg';
import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Footer() {
  return (
    <footer className={styles.footer}>
      <img className={styles.triangle} src={triangle} alt="" />
      <h2>Seize the Moment</h2>
      <p>
        Mythically master the market and empower your strategy with Real-Time Order Book, Real-Time
        Gains
      </p>
      <p className={styles.calltoaction}>Join us on our socials today!</p>
      <div className={styles.socials}>
        <a href="https://discord.gg/mythicswap" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faDiscord} style={{ fontSize: 36 }} />
        </a>
        <a href="https://twitter.com/mythicswap" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faTwitter} style={{ fontSize: 36 }} />
        </a>
      </div>
      <img className={styles.circle} src={circle} alt="" />
    </footer>
  );
}

export default Footer;
