import styles from './advisors.module.scss';
import george from '../images/george.png';
// import brian from '../images/brian.png';

const advisors = [
  {
    image: george,
    name: 'George Tung',
    title: 'Founder',
    company: 'CryptosRUs',
  },
  // {
  //   image: brian,
  //   name: 'Brian D Evans',
  //   title: 'Founder',
  //   company: 'BDE Ventures',
  // },
];

function AdvisorsSection() {
  return (
    <section className={styles.advisorsSection}>
      <h2>Advisors</h2>
      <div className={styles.advisors}>
        {advisors.map((advisor) => (
          <div key={advisor.name} className={styles.advisor}>
            <div className={styles.personRow}>
              <img src={advisor.image} alt="" />
              <div className={styles.info}>
                <p>{advisor.name}</p>
                <p className={styles.title}>
                  {`${advisor.title} at `}
                  <span>{advisor.company}</span>
                </p>
              </div>
            </div>
            <div className={styles.underline} />
          </div>
        ))}
      </div>
    </section>
  );
}

export default AdvisorsSection;
