import { useEffect } from 'react';
import AdvisorsSection from './components/advisors';
import Benefits from './components/benefits';
import Footer from './components/footer';
import Hero from './components/hero';
import TokenSection from './components/token';
import styles from './index.module.scss';
import ReactGA from 'react-ga4';
import InterfaceSection from './components/interface';
import WhyMythicSwap from './components/whyuse';

function HomePage() {
  useEffect(() => {
    ReactGA.initialize('G-1E79JQ3ZG6');
  }, []);

  return (
    <main className={styles.homePage}>
      <Hero />
      <Benefits />
      <InterfaceSection />
      <WhyMythicSwap />
      <TokenSection />
      <AdvisorsSection />
      <Footer />
    </main>
  );
}

export default HomePage;
